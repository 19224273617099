/** @jsxImportSource theme-ui */
import { PrismicServicePageBodyTestimonial } from 'root/graphql-types'
import { Flex } from 'theme-ui'
import RichText from 'src/components/organisms/RichText'
import { useGridColumnsWidth } from 'src/utils/useGridColumnsWidth'
import { ml, blockScrollYMobile } from 'src/gatsby-plugin-theme-ui/utils'
import { singleFullWidthHeadingSx } from 'src/gatsby-plugin-theme-ui/sizes'

export type TestimonialProps = { slice: PrismicServicePageBodyTestimonial }

const invertedColorsSx = {
  paragraph: { color: 'background' },
  h1: { color: 'background' },
  h2: { color: 'background' },
  h3: { color: 'background' },
  h4: { color: 'background' },
  h5: { color: 'background' },
  h6: { color: 'background' },
}

export const Testimonial = ({ slice }: TestimonialProps) => {
  const { primary } = slice
  const getColumnsWidth = useGridColumnsWidth()

  return (
    <div
      sx={{
        mb: ml('l3', 'xxl'),
        py: ml('l3', 'xxl2'),
        backgroundColor: 'text',
        marginLeft: singleFullWidthHeadingSx,
        width: '100vw',
        ...blockScrollYMobile(true),
      }}
    >
      <Flex sx={{ justifyContent: 'center', mb: 'l2' }}>
        <RichText
          content={primary?.quote}
          sxContainer={{
            textAlign: 'center',
            width: getColumnsWidth(8),
          }}
          sxElements={invertedColorsSx}
        />
      </Flex>
      <RichText
        content={primary?.author}
        sxContainer={{ textAlign: 'center' }}
        sxElements={invertedColorsSx}
      />
    </div>
  )
}
