/** @jsxImportSource theme-ui */
import { PrismicServicePageBodyRichText } from 'root/graphql-types'
import RichTextOrganism, {
  useRichTextCustomStyles,
} from 'src/components/organisms/RichText'
import { ml } from 'src/gatsby-plugin-theme-ui/utils'

export type RichTextProps = { slice: PrismicServicePageBodyRichText }

export const RichText = ({ slice }: RichTextProps) => {
  const { primary } = slice
  const { headings10ColsElements8Cols } = useRichTextCustomStyles()

  return (
    <RichTextOrganism
      sx={{ mb: ml('l3', 'xxl') }}
      className="Slice_RichText"
      content={primary?.content}
      sxElements={headings10ColsElements8Cols}
    />
  )
}
