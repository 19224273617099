/** @jsxImportSource theme-ui */
import { PrismicServicePageBodyCaseStudies } from 'root/graphql-types'
import OffsetProjects from 'src/components/pages/home/OffsetProjects'
import { ThinLine } from 'src/components/atoms/layout'
import { ml } from 'src/gatsby-plugin-theme-ui/utils'
import { RichText } from 'src/components/organisms'

export type CaseStudiesProps = { slice: PrismicServicePageBodyCaseStudies }

export const CaseStudies = ({ slice }: CaseStudiesProps) => {
  const { items, primary } = slice

  return (
    <div className="Slice_CaseStudies" sx={{ mb: ml('l3', 'xxl') }}>
      <RichText
        sxContainer={{ mb: ml('m2', 'l2'), 'text-align': 'center' }}
        content={primary?.title}
        newHeadingsStyles
      />
      <OffsetProjects projects={items as any} showMore={false} />
    </div>
  )
}
