/** @jsxImportSource theme-ui */
import { FC } from 'react'
import { useGridColumnsWidth } from 'src/utils/useGridColumnsWidth'
import { ProjectCard } from 'src/components/organisms'
import { Flex } from 'theme-ui'
import _ from 'lodash'
import { HomeProjectsProps } from './types'
import { getProjectGroupProps, useSetOffsetPositions } from './utils'
import { xs } from 'src/gatsby-plugin-theme-ui/utils'
import useReRenderOnMountKeyMobile from 'src/utils/useReRenderOnMountKeyMobile'

const OffsetProjects: FC<HomeProjectsProps> = ({ projects }) => {
  const getColumnsWidth = useGridColumnsWidth()
  useSetOffsetPositions()
  const reRenderOnMountKeyMobile =
    useReRenderOnMountKeyMobile('offset-projects')

  return (
    <div key={reRenderOnMountKeyMobile}>
      {_.map(projects, (project, i) => {
        const { projectCardProps } = getProjectGroupProps(project)
        const numCols =
          project.orientation === 'landscape'
            ? 9
            : Number(project.desktop_width) || 6
        const isLast = i === _.size(projects) - 1

        return (
          <Flex
            className={`offsetProject orientation_${project.orientation}`}
            key={i}
            sx={{
              justifyContent: i % 2 === 0 ? 'flex-end' : 'flex-start',
            }}
          >
            <div
              sx={{
                width: xs('100%', getColumnsWidth(numCols)),
                mb: xs(0, 'm'),
              }}
            >
              <ProjectCard
                {...projectCardProps}
                mb={isLast ? 0 : undefined}
                videoResolution={2}
                titleSize="HOME_PAGE"
              />
            </div>
          </Flex>
        )
      })}
    </div>
  )
}

export default OffsetProjects
