/** @jsxImportSource theme-ui */
import { PrismicServicePageBodyCustomerLogos } from 'root/graphql-types'
import { ClientsSlider } from 'src/components/organisms'
import { PrismicServicePageBodyCustomerLogosItemType } from 'root/graphql-types'
import { singleFullWidthHeadingSx } from 'src/gatsby-plugin-theme-ui/sizes'
import { ml, blockScrollYMobile } from 'src/gatsby-plugin-theme-ui/utils'

export type CustomerLogosProps = { slice: PrismicServicePageBodyCustomerLogos }

export const CustomerLogos = ({ slice }: CustomerLogosProps) => {
  const { primary } = slice
  const items = slice?.items as PrismicServicePageBodyCustomerLogosItemType[]

  return (
    <div
      className="Slice_CustomerLogos"
      sx={{
        // backgroundColor: 'footer',
        marginLeft: singleFullWidthHeadingSx,
        width: '100vw',
        ...blockScrollYMobile(true),
        // py: ml('l', 'xxl'),
        mb: ml('l3', 'xxl'),
      }}
    >
      <ClientsSlider
        heading={primary?.eyebrow_headline?.text || ''}
        itemsSlices={items}
      />
    </div>
  )
}
