import { ProjectGroup } from './types'
import { useEffect } from 'react'
import _ from 'lodash'
import { useBreakpointIndex } from '@theme-ui/match-media'

const OFFSET_SPACING = 104

export const useSetOffsetPositions = () => {
  const breakpoint = useBreakpointIndex()

  useEffect(() => {
    if (typeof document !== 'object') return

    const setPositions = () => {
      // @ts-ignore
      const projects: HTMLDivElement[] =
        document.getElementsByClassName('offsetProject')

      // reset marginTop so I can calculate correctly (needed for window resize)
      _.map(projects, (project) => {
        project.style.marginTop = '0px'
      })

      // ignore on mobile
      if (breakpoint < 1) return

      const projectsCalc = _.compact(
        _.map(projects, (project) => {
          const asset =
            project.querySelector('img') || project.querySelector('video')

          if (!asset) {
            console.log('___ ERROR cannot find asset in project card')
            return
          }

          return {
            container: project,
            assetHeight: asset.offsetHeight,
            y: project.offsetTop,
            orientation: project.className.match(/landscape/)
              ? 'landscape'
              : 'portrait',
          }
        })
      )

      _.each(projectsCalc, (p, i) => {
        if (i === 0) return

        const prevP = projectsCalc[i - 1]

        if (p.orientation === 'portrait') {
          if (prevP.orientation === 'portrait') {
            p.container.style.marginTop = `${
              -1 * (p.y - prevP.y - prevP.assetHeight + OFFSET_SPACING)
            }px`
          }
        }
      })
    }

    setTimeout(setPositions, 1000)
    window.addEventListener('resize', setPositions)

    return () => window.removeEventListener('resize', setPositions)
  }, [breakpoint])
}

export const getProjectGroupProps = (group: ProjectGroup) => {
  const data = group?.project?.document?.data
  const marginTop = group?.margin_top || 0
  const orientation = group.orientation

  return {
    marginTop,
    projectCardProps: {
      imageDesktop: data?.[`${orientation}_image_desktop`],
      imageMobile: data?.[`${orientation}image_mobile`],
      videoDesktop: data?.[`${orientation}VideoDesktop`],
      videoMobile: data?.[`${orientation}VideoMobile`],
      title: data?.preview_title || '',
      client: data?.client || '',
      slug: group?.project?.document?.uid || '',
    },
  }
}
