/** @jsxImportSource theme-ui */
import _ from 'lodash'
import {
  PrismicServicePageBodyFaq,
  PrismicServicePageBodyFaqItemType,
} from 'root/graphql-types'
import { ml } from 'src/gatsby-plugin-theme-ui/utils'
import { Grid } from 'src/components/atoms/layout'
import { HoverAccordionMobile } from 'src/components/organisms'
import { RichText } from 'src/components/organisms'
import * as textStyles from 'src/gatsby-plugin-theme-ui/text'

export type FAQProps = { slice: PrismicServicePageBodyFaq }

export const FAQ = ({ slice }: FAQProps) => {
  const { primary } = slice
  const items = slice.items as PrismicServicePageBodyFaqItemType[]

  return (
    <div className="Slice_FAQ">
      <Grid gap={ml('m2', 'l')} columns={ml(1, 2)} sx={{ mb: ml('l3', 'xxl') }}>
        <div>
          <RichText
            sxContainer={{
              position: 'sticky',
              top: ml('xl', 'xxl'),
            }}
            content={primary?.title}
          />
        </div>
        <div>
          <HoverAccordionMobile
            elements={_.map(items, ({ text, title }) => {
              return {
                close: <span sx={{ ...textStyles?.body }}>{title?.text}</span>,
                open: (
                  <span sx={{ ...textStyles?.bodySmall }}>{text?.text}</span>
                ),
              }
            })}
            itemOpenSx={{ pt: 's' }}
          />
        </div>
      </Grid>
    </div>
  )
}
