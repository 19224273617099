/** @jsxImportSource theme-ui */
import _ from 'lodash'
import { useResponsiveValue } from '@theme-ui/match-media'
import Sliding from 'src/components/molecules/Sliding'
import { h4 } from 'src/gatsby-plugin-theme-ui/text'
import { ml, sm, blockScrollYMobile } from 'src/gatsby-plugin-theme-ui/utils'
// @ts-ignore
import Cross from 'src/svg/cross_16px.svg'
import {
  PrismicServicePageBodyServiceSlider,
  PrismicServicePageBodyServiceSliderItemType,
} from 'root/graphql-types'

type SubServicesProps = {
  services?: PrismicServicePageBodyServiceSliderItemType[]
}

const SubServices = ({ services }: SubServicesProps) => (
  <div sx={{ display: 'flex' }}>
    {_.map(services, (s) => (
      <span
        sx={{
          display: 'inline-flex',
          'align-items': 'center',
        }}
      >
        <span>{s?.service_name?.text + ' '}</span>
        <span
          sx={{
            ml: 's',
            mr: 's',
            display: 'flex',
            'padding-bottom': ml('4px', '8px'),
          }}
        >
          <Cross />
        </span>
      </span>
    ))}
  </div>
)

export type ServiceSliderProps = { slice: PrismicServicePageBodyServiceSlider }

export const ServiceSlider = (props: ServiceSliderProps) => {
  const items = props.slice
    .items as PrismicServicePageBodyServiceSliderItemType[]

  const subServicesSlidingSpeed = useResponsiveValue(sm(75, 150))

  return (
    <div
      className="Slice_ServiceSlicer"
      sx={{ mb: ml('l3', 'xxl'), ...blockScrollYMobile(true) }}
    >
      <Sliding
        tagName="h4"
        sx={{ ...h4, width: '100%', mb: ml('xs', 's') }}
        direction="LEFT_TO_RIGHT"
        speedFactor={subServicesSlidingSpeed}
      >
        <SubServices services={items} />
      </Sliding>
      <Sliding
        tagName="span"
        sx={{ ...h4, width: '100%' }}
        direction="RIGHT_TO_LEFT"
        speedFactor={subServicesSlidingSpeed}
      >
        <SubServices services={items} />
      </Sliding>
    </div>
  )
}
