//

const calendlyIsLoaded = () => {
  // @ts-ignore
  return !!window?.Calendly?.initPopupWidget
}

const injectCalendlyWidget = (done: () => void) => {
  // (1) Already loaded
  // @ts-ignore
  if (calendlyIsLoaded()) {
    done()
    return
  }

  // (2) Not loaded, load it once
  const widgetJs = document.createElement('script')
  widgetJs.setAttribute(
    'src',
    'https://assets.calendly.com/assets/external/widget.js'
  )
  widgetJs.setAttribute('type', 'text/javascript')
  document.head.appendChild(widgetJs)

  const widgetCss = document.createElement('link')
  widgetCss.setAttribute(
    'href',
    'https://assets.calendly.com/assets/external/widget.css'
  )
  widgetCss.setAttribute('rel', 'stylesheet')
  document.head.appendChild(widgetCss)

  // (3) Wait for calendly to load
  const intervalId = setInterval(() => {
    // @ts-ignore
    if (calendlyIsLoaded()) {
      clearInterval(intervalId)
      done()
    }
  }, 100)
}

export const openCalendlyWidget = () => {
  injectCalendlyWidget(() => {
    // @ts-ignore
    window.Calendly.initPopupWidget({
      url: 'https://calendly.com/hyam-studios/chemistry-call',
    })
  })
}
