/** @jsxImportSource theme-ui */
import _ from 'lodash'
import { PrismicServicePageBodyTestimonialsSlider } from 'root/graphql-types'
import { HorizontalSlider, RichText } from 'src/components/organisms'
import { ml, blockScrollYMobile } from 'src/gatsby-plugin-theme-ui/utils'
import { H6, Body, BodyLarge8ColsCenter } from 'src/components/atoms/text'
import { replaceHtml } from 'src/utils/htmlReactParser'

export type TestimonialsSliderProps = {
  slice: PrismicServicePageBodyTestimonialsSlider
}

export const TestimonialsSlider = ({ slice }: TestimonialsSliderProps) => {
  const { primary, items } = slice

  return (
    <div className="Slice_TestimonialsSlider">
      <RichText
        sxContainer={{ mb: ml('m2', 'l2'), 'text-align': 'center' }}
        content={primary?.eyebrow_headline}
      />
      <div sx={{ ...blockScrollYMobile(false), mb: ml(0, 'xxl') }}>
        <HorizontalSlider
          elements={_.map(items, (item) => ({
            top: (
              <BodyLarge8ColsCenter
                sx={{ mb: ml('m2', 'l2'), textAlign: 'center' }}
              >
                {replaceHtml(item?.testimonial?.html || '', 'p', Body)}
              </BodyLarge8ColsCenter>
            ),
            bottom: (
              <H6 as="div" sx={{ textAlign: 'center' }}>
                {item?.person}
              </H6>
            ),
          }))}
        />
      </div>
      <div sx={{ pb: ml('l', 'xxl'), mt: ml(0, 'xxl') }} />
    </div>
  )
}
