/** @jsxImportSource theme-ui */
import { SliceZone as PrismicSliceZone, SliceZoneLike } from '@prismicio/react'
import * as slice from './sliceComponents'
import useReRenderOnMountKeyMobile from 'src/utils/useReRenderOnMountKeyMobile'

export type SlicesProps = {
  slices?: SliceZoneLike
}

export const SliceZone = (props: SlicesProps) => {
  const { slices } = props

  const reRenderOnMountKeyMobile = useReRenderOnMountKeyMobile('slize-zone')

  return (
    <PrismicSliceZone
      key={reRenderOnMountKeyMobile}
      slices={slices}
      components={{
        hero: slice.Hero,
        service_slider: slice.ServiceSlider,
        customer_logos: slice.CustomerLogos,
        testimonials_slider: slice.TestimonialsSlider,
        faq: slice.FAQ,
        video: slice.Video,
        rich_text: slice.RichText,
        case_studies: slice.CaseStudies,
        horizontal_divider: slice.HorizontalDivider,
        headline: slice.Headline,
        contact_block: slice.ContactBlock,
        testimonial: slice.Testimonial,
      }}
    />
  )
}
