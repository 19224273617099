/** @jsxImportSource theme-ui */
import _ from 'lodash'
import { SliceZone } from 'src/components/sliceZone'
import { PrismicServicePageDataType } from 'root/graphql-types'
import { SliceZoneLike } from '@prismicio/react'
import SEO from 'src/components/organisms/SEO'

export type LandingPageProps = {
  pageContext: PrismicServicePageDataType
}

const convertFaqSlicesToSchema = (slices: any) => {
  return _.flatten(
    _.compact(
      _.map(slices, (slice: any) => {
        if (slice?.slice_type === 'faq') {
          return _.map(slice?.items, (item) => ({
            '@type': 'Question',
            name: item?.title?.text,
            acceptedAnswer: {
              '@type': 'Answer',
              text: item?.text?.html,
            },
          }))
        }
      })
    )
  )
}

const LandingPage = ({ pageContext }: LandingPageProps) => {
  const slices = pageContext.body as SliceZoneLike

  const faqSchemaItems = convertFaqSlicesToSchema(slices)

  return (
    <div>
      <SEO
        schema_type={pageContext?.schema_type || ''}
        faqSchemaItems={faqSchemaItems}
        title={pageContext?.seo_title}
        image={pageContext?.seo_image_1200x1200?.fluid?.src}
        description={pageContext?.seo_description}
        alternate_languages={pageContext?.alternate_languages}
        lang={pageContext?.lang}
      />
      <SliceZone slices={slices} />
    </div>
  )
}

export default LandingPage
