/** @jsxImportSource theme-ui */
import { Flex } from 'theme-ui'
import { PrismicServicePageBodyHeadline } from 'root/graphql-types'
import RichText from 'src/components/organisms/RichText'
import { xs, ml } from 'src/gatsby-plugin-theme-ui/utils'
import * as textStyles from 'src/gatsby-plugin-theme-ui/text'
import { singleFullWidthHeadingSx } from 'src/gatsby-plugin-theme-ui/sizes'

export type HeadlineProps = { slice: PrismicServicePageBodyHeadline }

type HeadlineAs = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

export const Headline = ({ slice }: HeadlineProps) => {
  const { primary } = slice
  const main_text_html_tag = (primary?.main_text_html_tag || 'h2') as HeadlineAs
  const textStyle = (textStyles as any)?.[main_text_html_tag] || {}

  return (
    <Flex
      className="Slice_Headline"
      as={main_text_html_tag}
      sx={{
        mb: ml('l3', 'xxl'),
        height: xs('calc(var(--mobile-vh, 100vh))', '100vh'),
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        'z-index': 1,
        backgroundColor: 'text',
        marginLeft: singleFullWidthHeadingSx,
        width: '100vw',
      }}
    >
      <RichText
        newHeadingsStyles
        paragraphStyleAsSpan
        sxContainer={{
          ...textStyle,
          textAlign: primary?.main_text_align,
          color: 'background',
        }}
        content={primary?.main_text}
      />
    </Flex>
  )
}
