/** @jsxImportSource theme-ui */
import { FC } from 'react'
import { Link } from 'src/components/atoms'
import { Flex } from 'theme-ui'
import { PrismicStructuredTextType } from 'graphql-types'
import { h1 } from 'src/gatsby-plugin-theme-ui/text'
import { motion as motionEnabled } from 'framer-motion'
import { motion as motionDisabled } from 'src/utils/disableFramer'

import { H1, H2 } from 'src/components/atoms/text'
import { ml, xs } from 'src/gatsby-plugin-theme-ui/utils'
import { useBreakpointIndex } from '@theme-ui/match-media'
import {
  replaceHtml,
  addTrailingWhiteSpaceToSpan as addTrailingWhiteSpaceToTag,
} from 'src/utils/htmlReactParser'
// @ts-ignore
import ArrowSmall from 'src/svg/arrow_small.svg'
import {
  yOpacityProps,
  DELAY_AFTER_SHUTTER,
  STAGGER_TIME,
} from 'src/animations'
import { DataLayerEvent } from 'src/utils/gtm'
import { keyframes } from '@emotion/react'
import { isMobile } from 'react-device-detect'
import { RichText } from 'src/components/organisms'

const arrowBounceLeft = keyframes`
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-16px);
  }
  100% {
    transform: translateX(0px);
  }
`
const arrowBounceRight = keyframes`
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(16px);
  }
  100% {
    transform: translateX(0px);
  }
`
const arrowBounceLeftSm = keyframes`
  0% {
    transform: translateX(16px);
  }
  50% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(16px);
  }
`
const arrowBounceRightSm = keyframes`
  0% {
    transform: translateX(-16px);
  }
  50% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-16px);
  }
`

type Props = {
  headline?: PrismicStructuredTextType | null
  headlineText?: string
  cta?: string | null
  dataLayer?: DataLayerEvent
  title?: string
  headlineRichText?: any
  onClick?: () => void
  disableAnimations?: boolean
}

const FullViewportHeadline: FC<Props> = ({
  headline,
  headlineText,
  cta,
  title,
  headlineRichText,
  onClick,
  disableAnimations,
}) => {
  const breakpoint = useBreakpointIndex()

  const motion = disableAnimations ? motionDisabled : motionEnabled

  return (
    <motion.div
      {...yOpacityProps({ delay: DELAY_AFTER_SHUTTER + STAGGER_TIME })}
      sx={{
        display: 'flex',
        'justify-content': 'center',
        height: ml(
          'calc(var(--mobile-vh, 100vh) * 0.9 - 64px)',
          'calc(100vh * 0.9 - 64px)'
        ),
      }}
    >
      <Flex
        sx={{
          mt: ml('l', 'l2'),
          mb: ml('l3', 'xxl'),
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          'z-index': 1,
        }}
      >
        {title ? <H1 sx={{ textAlign: 'center' }}>{title}</H1> : null}
        <H2
          sx={{
            mt: ml('m', 'l'),
            mb: ml('m', 'l'),
            textAlign: 'center',
            span: {
              display: xs('inline', 'block'),
            },
          }}
        >
          {headline?.html
            ? replaceHtml(
                addTrailingWhiteSpaceToTag(
                  headline?.html,
                  'p',
                  breakpoint <= 2
                ),
                'p',
                'span'
              )
            : null}
          {headlineText ? headlineText : null}
          {headlineRichText ? (
            <RichText
              headingsAsSpan
              headingsNoStyle
              sxContainer={{ textAlign: 'center' }}
              content={headlineRichText}
            />
          ) : null}
        </H2>
        {cta && (
          <Link
            onClick={onClick}
            resolveTo={!onClick ? { type: 'contact' } : undefined}
            sx={{
              ...h1,
              textDecoration: 'none',
              textAlign: 'center',
              cursor: 'pointer',
            }}
            dataLayer={{
              event: 'Click',
              Text: 'Contact',
            }}
          >
            <Flex
              sx={{
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                sx={{
                  mr: ml('m', 'm'),
                  width: '50px',
                  animation: xs(
                    `${arrowBounceLeftSm} 1s ease infinite`,
                    `${arrowBounceLeft} 1s ease infinite`
                  ),
                }}
              >
                <ArrowSmall />
              </div>
              <div sx={{ width: xs('70%', '100%') }}>{cta}</div>
              <div
                sx={{
                  display: 'inline-block',
                  width: '50px',
                  ml: ml('m', 'm'),
                  animation: xs(
                    `${arrowBounceRightSm} 1s ease infinite`,
                    `${arrowBounceRight} 1s ease infinite`
                  ),
                }}
              >
                <ArrowSmall
                  sx={{
                    transform: 'rotate(180deg)',
                  }}
                />
              </div>
            </Flex>
          </Link>
        )}
      </Flex>
    </motion.div>
  )
}

export default FullViewportHeadline
