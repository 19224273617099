/** @jsxImportSource theme-ui */
import FullViewportHeadline from 'src/components/molecules/FullViewportHeadline'
import { PrismicServicePageBodyHero } from 'root/graphql-types'
import { openCalendlyWidget } from 'src/utils/calendly'

export type HeroProps = { slice: PrismicServicePageBodyHero }

export const Hero = ({ slice }: HeroProps) => {
  const { primary } = slice

  return (
    <div className="Slice_Hero">
      <FullViewportHeadline
        disableAnimations
        title={primary?.eyebrow_headline?.text || undefined}
        headlineRichText={primary?.title || undefined}
        cta={primary?.button_label || undefined}
        onClick={
          primary?.button_type === 'Link to Calendly'
            ? openCalendlyWidget
            : undefined
        }
      />
    </div>
  )
}
