/** @jsxImportSource theme-ui */
import { Flex } from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicServicePageBodyContactBlock } from 'root/graphql-types'
import { Link } from 'src/components/atoms'
// @ts-ignore
import ArrowSmall from 'src/svg/arrow_small.svg'
import { h5 } from 'src/gatsby-plugin-theme-ui/text'
import { ml } from 'src/gatsby-plugin-theme-ui/utils'
import { keyframes } from '@emotion/react'
import {
  singleFullWidthHeadingSx,
  gridMarginArray,
} from 'src/gatsby-plugin-theme-ui/sizes'
import { RichText } from 'src/components/organisms'
import { getPrismicImage, ImageProp } from 'src/utils/gatsby'
import { openCalendlyWidget } from 'src/utils/calendly'

const arrowBounceLeft = keyframes`
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-16px);
  }
  100% {
    transform: translateX(0px);
  }
`
const arrowBounceRight = keyframes`
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(16px);
  }
  100% {
    transform: translateX(0px);
  }
`

export type ContactBlockProps = { slice: PrismicServicePageBodyContactBlock }

export const ContactBlock = ({ slice }: ContactBlockProps) => {
  const { primary } = slice

  const isCalendlyCta = primary?.cta_type === 'Link to Calendly'

  const hasImage = !!primary?.image?.fluid

  return (
    <div
      sx={{
        backgroundColor: 'footer',
        marginLeft: singleFullWidthHeadingSx,
        width: '100vw',
        pt: ml('l', 'xxl'),
      }}
    >
      <div sx={{ mx: gridMarginArray }}>
        <RichText
          headingsAsSpan
          sxContainer={{ mb: ml('m2', 'l2'), 'text-align': 'center' }}
          content={primary?.title}
        />
        <RichText
          headingsAsSpan
          sxContainer={{
            display: 'block',
            textAlign: 'center',
            mb: ml('xl', 'l2'),
          }}
          content={primary?.headline}
        />
        <Link
          onClick={isCalendlyCta ? openCalendlyWidget : undefined}
          resolveTo={isCalendlyCta ? undefined : primary?.cta_link}
          dataLayer={{
            event: 'Click',
            // pagePath: 'About Us ',
            // pageTitle: 'About',
            Text: 'Contact',
          }}
          sx={{ ...h5, textDecoration: 'none', textAlign: 'center' }}
        >
          <ArrowSmall
            sx={{
              mr: ml('m', 'm'),
              animation: `${arrowBounceLeft} 1s ease infinite`,
            }}
          />
          <RichText
            headingsAsSpan
            sxContainer={{ display: 'inline-block' }}
            content={primary?.cta_label}
          />
          <div
            sx={{
              display: 'inline-block',
              animation: `${arrowBounceRight} 1s ease infinite`,
            }}
          >
            <ArrowSmall
              sx={{ ml: ml('m', 'm'), transform: 'rotate(180deg)' }}
            />
          </div>
        </Link>
        {hasImage ? (
          <Flex
            sx={{
              justifyContent: 'center',
              pb: ml('xl', 'xxl'),
              pt: ml('l', 'xxl'),
              width: '100%',
              borderBottomColor: 'text',
              borderBottomStyle: 'solid',
              borderBottomWidth: '1px',
            }}
          >
            <GatsbyImage
              className="GatsbyImageClassName"
              sx={{
                width: 200,
                height: 200,
                display: 'flex',
                justifyContent: 'center',
              }}
              alt={primary?.image?.alt || ''}
              image={getPrismicImage(
                primary?.image as ImageProp,
                'fullWidth',
                'Growing plant'
              )}
            />
          </Flex>
        ) : (
          <div
            className="ContactBlock_NoImage"
            sx={{
              pb: ml('l3', 'xxl'),
              borderBottomColor: 'text',
              borderBottomStyle: 'solid',
              borderBottomWidth: '1px',
            }}
          />
        )}
      </div>
    </div>
  )
}
