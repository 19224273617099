/** @jsxImportSource theme-ui */
import { PrismicServicePageBodyVideo } from 'root/graphql-types'
import { VideoWithCursor } from 'src/components/organisms'
import { ml } from 'src/gatsby-plugin-theme-ui/utils'

export type VideoProps = {
  slice: PrismicServicePageBodyVideo & {
    primary: {
      videoDesktop: any // created by addVimeoToLandingPages()
      videoMobile: any // created by addVimeoToLandingPages()
    }
  }
}

export const Video = ({ slice }: VideoProps) => {
  const { primary } = slice

  return (
    <div className="Slice_Video" sx={{ mb: ml('l3', 'xxl') }}>
      <VideoWithCursor
        responsiveAssetSx={{ pb: 0 }}
        videoDesktop={primary?.videoDesktop}
        videoMobile={primary?.videoMobile}
        muted
        showSoundToggle
        loadOnUserEvent={false}
        autoplay={false}
      />
    </div>
  )
}
